<template>
  <div>
    <nav-title></nav-title>
    <nav-header></nav-header>
    <index-device></index-device>
    <index-swiper></index-swiper>
    <index-customer></index-customer>
    <section :style="`background-image: url(${section6bg})`" class="section6">
    </section>
    <index-try></index-try>
    <section :style="`background-image: url(${section7bg})`" class="section7">
    </section>
    <partner-banner></partner-banner>
    <nav-footer></nav-footer>
  </div>  
</template>

<script>
import navTitle from '@component/nav-title'
import navHeader from '@component/nav-header'
import indexDevice from '@component/index-device'
import indexSwiper from '@component/index-swiper'
import indexCustomer from '@component/index-customer'
import indexTry from '@component/index-try'
import partnerBanner from '@component/partner-banner'
import navFooter from '@component/footer'
import section6bg from '@images/6.jpg'
import section7bg from '@images/7.jpg'
export default {
  data () {
    return {
      section6bg,
      section7bg,
      apkUrl: '',
      title: 'Vue.js From LegoFlow'
    }
  },
  components: {
    navTitle,
    navHeader,
    indexDevice,
    indexSwiper,
    indexCustomer,
    indexTry,
    partnerBanner,
    navFooter
  },
}
</script>

<style lang="scss">
  
  section.section6, section.section7 {
    height: 496px;
    min-width: 1200px;
    background-origin: center;
    background-position-x: center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  section.section7 {
    height: 360px;
  }
  @media only screen and (max-width: 1920px) {
    section.section6 {
      background-size: auto 496px;
    }
    section.section7 {
      background-size: auto 360px;
    }
  }
  
</style>
