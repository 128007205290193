<template>
	<section class="device">
		<sction-title
			title="Marki for All Devices"/>
			<div class="device-list">
				<div class="device-item">
					<img src="@images/device-pc.png" alt="">
					<h6>Web</h6>
					<p>Unlock valuable business insight with Marki watermarks. 
						Export watermarked photos, get overall attendance statistics, 
						track real-time GPS location and monitor performance in one place. </p>
				</div>
				<div class="device-item">
					<img src="@images/device-mobile.png" alt="">
					<h6>Mobile APP</h6>
					<p>Capture what happened in real-time on the Marki app! 
						Taking photos with Marki anti-tamper watermarks, get summary report for attendance, 
						interact with your team members and share the watermarked photos.</p>
				</div>
			</div>
	</section>
</template>

<script>
import sctionTitle from '@component/section-title'
export default {
	data() {
		return {
			
		}
	},
	components: {
		sctionTitle
	}
}
</script>

<style scoped lang="scss">
	section.device {
		margin-top: 40px;
	}
	.device-list {
		display: flex;
		justify-content: space-between;
		width: 850px;
		margin: 0 auto;
		padding: 20px 0 110px;

		.device-item {
			width: 380px;
			text-align: center;
			color: #232325;
			h6 {
				font-size: 24px;
				line-height: 28px;
				margin: 30px auto 10px;
			}
			p {
				font-size: 16px;
				line-height: 24px;
				color: #50607A;
			}
		}
	}
</style>
