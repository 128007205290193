var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "comment" },
    [
      _c("sction-title", { attrs: { title: "What our customers say?" } }),
      _c(
        "div",
        { staticClass: "comment-cover" },
        _vm._l(_vm.contentList, function(item, index) {
          return _c("div", { key: index, staticClass: "comment-item" }, [
            _c("div", { staticClass: "comment-main" }, [
              _c("h6", [_vm._v(_vm._s(item.title))]),
              _c("p", [_vm._v(_vm._s(item.desc))])
            ]),
            _c("div", { staticClass: "comment-footer" }, [
              _c("p", [
                _c("img", {
                  staticClass: "avatar",
                  attrs: { src: item.avatar }
                }),
                _c("span", [_vm._v(_vm._s(item.name))])
              ]),
              _c("span", { staticClass: "say" }, [_vm._v("”")])
            ])
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }