<template>
  <div>
    <div v-if="showVideo" class="partner-cover">
			<div class="partner-content">
				<div style="">
					<partner classStyle="pc"></partner>
				</div>
				<p>	<i @click="showVideo = false" class="iconfont iconclose"></i></p>
			</div>
		</div>

    <div @click="showVideo = true" class="partner-banner">
      <img src="@images/partner-pc.png" alt="" srcset="">
    </div>
  </div>
</template>

<script>
import partner from '@views/mobile/partner'
export default {
  data () {
    return {
      showVideo: false
    }
  },
  components: {
    partner
  }
}
</script>

<style lang="scss" scoped>
  .partner-banner {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #2E5BFF;
    z-index: 19;
    position: fixed;
    bottom: 0;
    cursor: pointer;

    display: none;
    img {
      width: 954px;
      height: 130px;
    }
  }
  .partner-cover {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 29;
		.partner-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: flex;
      padding: 100px 200px;
      overflow: hidden;
      // font-size: 80px;
      //设置滚动条样式
      ::-webkit-scrollbar {
          width: 6px;
          height: 6px;
      }

      //置底的滑动槽
      ::-webkit-scrollbar-track {
          border-radius: 6px;
          background-color: #ebecee;
      }

      //滚动条滑块和第一条必须书写，不写的话，无法实现
      ::-webkit-scrollbar-thumb {
          border-radius: 6px;
          background-color: #d4d7da;
      }
      >div {
        width: 576px;
        height: 640px;
        background: #fff;
        overflow: scroll;
      }
			p {
				text-align: right;
				color: #fff;
				font-size: 16px;
				margin-left: 20px;
				cursor: pointer;
			}
		}
	}
</style>
