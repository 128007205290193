<template>
	<div @mouseenter="showTip = false" @mouseleave="showTip = true" :style="{width: `${width}px`}" class="header-content">
		<div v-if="msgType === 1">
			<h2>Increase Field Work Management<br/>Efficiency with Marki</h2>
			<h6>Works happens anywhere. You need an employee<br/>tracking app that can keep up.</h6>
		</div>
		<div class="type2" v-else>
			<h2>Try Marki for Free!</h2>
			<h6>Say goodbye to inefficiency, wasted time, frustration, <br/>uncertainty, clunky timesheets</h6>
		</div>
		
		<div class="input-content">
			<div class="input-box">
				<input v-model="email" placeholder="Your email address" type="text">
				<button class="btn" @click="sendMsg">Try Marki Free!</button>
				<div v-if="showTip && msgType === 1" class="tips">Safe & No credit card required</div>
			</div>
			<p class="success" v-if="successMsg">{{ successMsg }}</p>
			<p class="fail" v-if="failMsg">{{ failMsg }}</p>
		</div>
		<slot></slot>
	</div>
</template>

<script>
export default {
	data() {
		return {
			email: '',
			showMsg: false,
			buttonDisabled: true,
			msg: 'We have sent an email to your mailbox',
			successMsg: '',
			failMsg: '',
			showTip: true,
		}
	},
	props: {
		msgType: {
			type: Number,
			default: 1
		},
		width: {
			type: Number,
			default: 1130
		}
		
	},
	methods: {
		sendMsg() {
			window.copyTxt('')
			if (this.buttonDisabled) {
				this.failMsg = 'Please input the correct email address!'
				this.successMsg = ''
				return
			}
			let email = this.email
			const self = this
			function sendAjax() {
				//构造表单数据
				var formData = new FormData();
				formData.append('email', email);
				//创建xhr对象
				var xhr = new XMLHttpRequest();
				//设置xhr请求的超时时间
				xhr.timeout = 3000;
				//设置响应返回的数据格式
				xhr.responseType = "application/json";
				//创建一个 post 请求，采用异步
				xhr.open('POST', 'https://api.markicam.com/wms/email/collect', true);
				//注册相关事件回调处理函数
				xhr.onload = function(e) { 
					if(this.status == 200 || this.status == 304){
							if (this.responseText)
							try {
								let res = JSON.parse(this.responseText)
								if (!res.code) {
									self.successMsg = self.msg
									self.failMsg = ''
								} else {
									self.failMsg = res.msg
									self.successMsg = ''
								}
							} catch (e) {
								console.log(e)
							}
					}
				};
				xhr.ontimeout = function(e) {
				console.log("Timeout: ", e);
				};
				xhr.onerror = function(e) {  
				console.log("Error: ", e);
				};
				xhr.upload.onprogress = function(e) { 
				};
				
				//发送数据
				xhr.send(formData);
			}

			sendAjax()

			
			// if (this.buttonDisabled) {
			// 	this.failMsg = 'please enter corret Email address!'
			// 	this.successMsg = ''
			// 	return
			// }
			// let formData = new FormData();

			//  formData.append("email", this.email);
			//  window.API.collectEmail.post(formData)
      //   .then(res => {
      //     if (!res.code) {
			// 			this.successMsg = this.msg
			// 			this.failMsg = ''
			// 		} else {
			// 			this.failMsg = res.msg
			// 			this.successMsg = ''
			// 		}
			// 	})
		}
	},
	watch: {
		email: {
			handler(val) {
				if (val.match(/^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/)) {
					this.buttonDisabled = false
					console.log(val)
				} else {
					this.buttonDisabled = true
				}
				
			}
		}
	},
}
</script>

<style scoped lang="scss">
	button {
		cursor: pointer;
		border: none;
		::after {
			border: none;
			outline: none;
		}
	}
	.header-content {
		position: relative;
		width: 1130px;
		margin: 0 auto;
		text-align: left;
		color: #fff;
		box-sizing: border-box;
		padding-top: 80px;

		h2 {
			font-size: 48px;
			line-height: 60px;
		}
		h6 {
			font-size: 24px;
			line-height: 36px;
			margin: 20px 0 30px 0;
			font-weight: normal;
		}
		.type2 {
			color:  #50607A;
			h2 {
				color: #2E5BFF;
			}
		}
		.input-content {
			position: relative;
		}
		.input-content p {
			color: #04C4A1;
			margin-top: 10px;
			font-size: 18px;
			line-height: 21px;
			position: absolute;
			top: 68px;
		}
		p.fail {
			color: #FF371C;
		}
		.input-box {
			height: 70px;
			margin-bottom: 66px;
			line-height: 70px;
			display: flex;
			align-items: center;
			font-size: 22px;
			position: relative;
			input {
				width: 402px;
				height: 100%;
				box-sizing: border-box;
				padding: 0 24px;
				color: #232325;
				::-webkit-input-placeholder {
					color: #ADB0B7;
				}
			}
			@keyframes move {
				0% {
					top: -34px;
				}
				50% {
					top: -42px;
				}
				100% {
					top: -34px;
				}
			}
			.tips {
				font-size: 14px;
				color: #fff;
				width: 220px;
				height: 40px;
				background-repeat: no-repeat;
				background-size: 100%;
				position: absolute;
				left: 420px;
				top: -34px;
				line-height: 30px;
				box-sizing: border-box;
				padding: 0 0 0 9px;
				animation: move 1s linear infinite;
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAaQAAABNBAMAAAAbXg34AAAAMFBMVEUAAAADxKEAv58DxKEEw58Av58ExKIExKAEw6ADxKEFxJ8DxJ8ExKEEw58Av58ExKETgAd3AAAAD3RSTlMA7yCvQBC/v5DfcFDPgDAXumpkAAAA/UlEQVRo3u3az00CcRTE8WE1xqgnLyZeXCvREuzAWIGJ0gJnLhRCB7RACXRANiT8SSDDks0PaICQmcyng+/lvXd4qH5oZVHjnWae8UEzS9BN45fEJClIkoIkKUiSgiQpSJKCJClIkoIkKUiSgiQpSJKCJClIkoIkKUiSgiQpSJKCJClIkoIkKUiSgiQpSJKCJClwTHqjG/zSzMLx9b0y+31/qXEZFc+M4OCRJ+svOLjn0fYTFu5YNFN46LH4g4l5KXqFi0nZex6j4WDYFW1MRkOrnFkD+OiK+vBxazYaWg9srWoYuSHZjOHkyWw0tL5dzu+THnc+O7ZT/c9wNXu0UnX7iyqXPwAAAABJRU5ErkJggg==');
			}
			button {
				width: 220px;
				height: 100%;
				background: #2E5BFF;
				box-sizing: border-box;
				color: #fff;
				
			}
			button[disabled] {
					background: #50607A;
				}
		}
	}
</style>