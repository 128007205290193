var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header-content",
      style: { width: _vm.width + "px" },
      on: {
        mouseenter: function($event) {
          _vm.showTip = false
        },
        mouseleave: function($event) {
          _vm.showTip = true
        }
      }
    },
    [
      _vm.msgType === 1
        ? _c("div", [_vm._m(0), _vm._m(1)])
        : _c("div", { staticClass: "type2" }, [
            _c("h2", [_vm._v("Try Marki for Free!")]),
            _vm._m(2)
          ]),
      _c("div", { staticClass: "input-content" }, [
        _c("div", { staticClass: "input-box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email"
              }
            ],
            attrs: { placeholder: "Your email address", type: "text" },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              }
            }
          }),
          _c("button", { staticClass: "btn", on: { click: _vm.sendMsg } }, [
            _vm._v("Try Marki Free!")
          ]),
          _vm.showTip && _vm.msgType === 1
            ? _c("div", { staticClass: "tips" }, [
                _vm._v("Safe & No credit card required")
              ])
            : _vm._e()
        ]),
        _vm.successMsg
          ? _c("p", { staticClass: "success" }, [
              _vm._v(_vm._s(_vm.successMsg))
            ])
          : _vm._e(),
        _vm.failMsg
          ? _c("p", { staticClass: "fail" }, [_vm._v(_vm._s(_vm.failMsg))])
          : _vm._e()
      ]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _vm._v("Increase Field Work Management"),
      _c("br"),
      _vm._v("Efficiency with Marki")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [
      _vm._v("Works happens anywhere. You need an employee"),
      _c("br"),
      _vm._v("tracking app that can keep up.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", [
      _vm._v("Say goodbye to inefficiency, wasted time, frustration, "),
      _c("br"),
      _vm._v("uncertainty, clunky timesheets")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }