var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nav-header" }, [
    _c(
      "div",
      { staticClass: "header-cover" },
      [
        _c("div", {
          staticClass: "header-img",
          style:
            "background-image: url(//biu-cn.dwstatic.com/zbshenqi/20210105/c2dc286c5932997dd9a3d939823d6999.png?w=1920&h=700)"
        }),
        _c("div", { staticClass: "video-mark" }),
        _c(
          "get-message",
          [
            _c("get-marki"),
            _c("img", {
              staticClass: "play",
              attrs: { src: _vm.play, alt: "" },
              on: {
                click: function($event) {
                  _vm.showVideo = true
                }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tab-list" },
      _vm._l(_vm.itemMap, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "tab-item" },
          [
            _c("img", { attrs: { src: item.img, alt: "" } }),
            _c("h4", [_vm._v(_vm._s(item.title))]),
            _c("p", [_vm._v(_vm._s(item.content))]),
            _c("router-link", { attrs: { to: item.link } }, [
              _vm._v("See more")
            ])
          ],
          1
        )
      }),
      0
    ),
    _vm.showVideo
      ? _c("div", { staticClass: "video-cover" }, [
          _c("div", { staticClass: "video-content" }, [
            _vm._m(0),
            _c("p", [
              _c("i", {
                staticClass: "iconfont iconclose",
                on: {
                  click: function($event) {
                    _vm.showVideo = false
                  }
                }
              })
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "video",
      {
        staticStyle: { width: "70vw" },
        attrs: { controls: "", playsinline: "", autoplay: "", loop: "" }
      },
      [
        _c("source", {
          attrs: {
            src:
              "http://biu-cn.dwstatic.com/marki/20201106/416fb4f33633ba0d483a231518d25e3c.mp4",
            type: "video/mp4"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }