var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "device" },
    [
      _c("sction-title", { attrs: { title: "Marki for All Devices" } }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "device-list" }, [
      _c("div", { staticClass: "device-item" }, [
        _c("img", {
          attrs: { src: require("@images/device-pc.png"), alt: "" }
        }),
        _c("h6", [_vm._v("Web")]),
        _c("p", [
          _vm._v(
            "Unlock valuable business insight with Marki watermarks. \n\t\t\t\t\tExport watermarked photos, get overall attendance statistics, \n\t\t\t\t\ttrack real-time GPS location and monitor performance in one place. "
          )
        ])
      ]),
      _c("div", { staticClass: "device-item" }, [
        _c("img", {
          attrs: { src: require("@images/device-mobile.png"), alt: "" }
        }),
        _c("h6", [_vm._v("Mobile APP")]),
        _c("p", [
          _vm._v(
            "Capture what happened in real-time on the Marki app! \n\t\t\t\t\tTaking photos with Marki anti-tamper watermarks, get summary report for attendance, \n\t\t\t\t\tinteract with your team members and share the watermarked photos."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }