var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showVideo
      ? _c("div", { staticClass: "partner-cover" }, [
          _c("div", { staticClass: "partner-content" }, [
            _c("div", {}, [_c("partner", { attrs: { classStyle: "pc" } })], 1),
            _c("p", [
              _c("i", {
                staticClass: "iconfont iconclose",
                on: {
                  click: function($event) {
                    _vm.showVideo = false
                  }
                }
              })
            ])
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "partner-banner",
        on: {
          click: function($event) {
            _vm.showVideo = true
          }
        }
      },
      [
        _c("img", {
          attrs: { src: require("@images/partner-pc.png"), alt: "", srcset: "" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }