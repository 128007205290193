<template>
  <div class="nav-header">
		<div class="header-cover">
			<!-- <video playsinline autoplay muted loop poster="@images/logo.png" id="bgvid">
  			<source src="http://biu-cn.dwstatic.com/zbshenqi/20201102/896d80c74205ac9d89e974c99c9df070.mp4" type="video/mp4">
			</video> -->
			<div :style="`background-image: url(//biu-cn.dwstatic.com/zbshenqi/20210105/c2dc286c5932997dd9a3d939823d6999.png?w=1920&h=700)`" class="header-img"></div>
			<div class="video-mark">
			</div>
			<get-message>
					<get-marki></get-marki>	
					<img @click="showVideo = true" class="play" :src="play" alt="">
			</get-message>
		</div>

		<div class="tab-list">
			<div v-for="(item, index) in itemMap" :key="index" class="tab-item">
				<img :src="item.img" alt="">
				<h4>{{ item.title }}</h4>
				<p>{{item.content}}</p>
				<router-link :to="item.link">See more</router-link>
			</div>
		</div>

		<div v-if="showVideo" class="video-cover">
			<div class="video-content">
				<video style="width: 70vw" controls playsinline autoplay loop >
					<source src="http://biu-cn.dwstatic.com/marki/20201106/416fb4f33633ba0d483a231518d25e3c.mp4" type="video/mp4">
				</video>
				<p>	<i @click="showVideo = false" class="iconfont iconclose"></i></p>
			</div>
			
		</div>
  </div>
</template>

<script>
import getMarki from '@component/get-marki'
import getMessage from '@component/get-message'

import play from '@images/play.png'
import img1 from '@images/1-1.png'
import img2 from '@images/1-2.png'
import img3 from '@images/1-3.png'
import img4 from '@images/1-4.png'

export default {
  data () {
    return {
			play,
			showVideo: false,
			itemMap: [
				{
					img: img1,
					title: 'Watermarks',
					content: 'Auto time and location\nCustomized formatting\nCloud storage',
					link: '/features?anchor=Watermarks'
				},{
					img: img2,
					title: 'Auto Reporting',
					content: 'Export anytime, anywhere\nDetailed information',
					link: '/features?anchor=Reporting'
				},{
					img: img3,
					title: 'GPS Tracking',
					content: 'Performance monitoring\nAccurate location\nRoute tracking',
					link: '/features?anchor=GPS'
				},{
					img: img4,
					title: 'Team Moment',
					content: 'Sharing\nEasy monitoring',
					link: '/features?anchor=Team'
				}
			]
    }
	},
	components: {
		getMarki,
		getMessage
	},
  props: {
  }
}
</script>

<style scoped lang="scss">
	.video-cover {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 299;
		background: rgba(0, 0, 0, 0.8);
		.video-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			padding: 100px 200px;
			p {
				text-align: right;
				color: #fff;
				font-size: 16px;
				margin-left: 20px;
				cursor: pointer;
			}
		}
	}
	button {
		cursor: pointer;
		border: none;
		::after {
			border: none;
			outline: none;
		}
	}
	.header-cover .header-img {
		height: 700px;
		min-width: 1200px;
		background-origin: center;
		background-position-x: center;
		background-size: 100%;
		background-repeat: no-repeat;
		width: 100%;
		position: absolute;
		z-index: -11;
	}

	@media only screen and (max-width: 1920px) {
		.header-cover .header-img {
			background-size: auto 700px;
		}
	}
	.play {
		position: absolute;
    right: 160px;
    top: 50%;
    cursor: pointer;
	}
  .header-cover {
		height: 700px;
		width: 100%;
		// overflow: hidden;
		position: relative;
		#bgvid {
			width: 100%;
			position: absolute;
			z-index: -11;
		}
		.video-mark {
			width: 100%;
			position: absolute;
			height: 100%;
			z-index: -1;
			background-color: rgba(0, 0, 0, 0.8);
		}
	}
	.tab-list {
		width: 1130px;
		margin: 0 auto;
		display: flex;
		.tab-item {
			width: 245px;
			height: 300px;
			background-color: #fff;
			margin-right: 50px;
			box-sizing: border-box;
			text-align: center;
			padding-top: 30px;
			position: relative;
			top: -72px;
			display: inline-block;
			border: 1px solid #E9ECF3;
			box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.08);
			.tab-image {
				width: 100px;
				height: 100px;
				background-size: cover;
				margin: 0 atuo 20px;
			}
			img {
				width: 100px;
				height: 100px;
			}
			&:last-child {
				margin-right: 0;
			}
			h4 {
				font-size: 18px;
				line-height: 22px;
				margin-bottom: 10px;
				color: #232325;
				margin-top: 20px;
			}
			p {
				font-size: 14px;
				line-height: 21px;
				color: #50607A;
				margin-bottom: 10px;
				white-space: pre-wrap;
			}
			a {
				text-decoration: none;
				color: #2E5BFF;
				font-size: 14px;
			}
		}
	}
</style>
