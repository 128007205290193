<template>
	<section class="comment">
		<sction-title
			title="What our customers say?"/>
			<div class="comment-cover">
				<div v-for="(item, index) in contentList" :key="index" class="comment-item">
					<div class="comment-main">
						<h6>{{ item.title }}</h6>
						<p>{{ item.desc }}</p>
					</div>
					<div class="comment-footer">
						<p>
							<img :src="item.avatar" class="avatar">
							<span>{{ item.name }}</span>
						</p>
						<span class="say">”</span>
					</div>
				</div>
			</div>
	</section>
</template>

<script>
import sctionTitle from '@component/section-title'
import avatar1 from '@images/4-1.png'
import avatar2 from '@images/4-2.png'
import avatar3 from '@images/4-3.png'
export default {
	data() {
		return {
			selected: 0,
			setT: null,
			contentList: [{
				title: 'How can Marki help manage your team?',
				desc: 'I can receive real-time feedback and manage my team in a more safe and effective way.',
				name: 'David Smith',
				avatar: avatar1
			}, {
				title: "Looking for a way to up team's efficeiency?",
				desc: 'I spend less time on tracking employees with Marki. Now I can easily track their performance corresponded to each location. ',
				name: 'Chris Wong',
				avatar: avatar2
			}, {
				title: 'Marki is the best working app I have ever used.',
				desc: 'I feel trusted by my boss with Marki watermarks, and it simplified my reporting process so that I can concentrate on my work.',
				name: 'Finn',
				avatar: avatar3
			}]
		}
	},
	components: {
		sctionTitle
	},
	methods: {
		setSelected(index) {
			clearTimeout(this.setT)
			this.loop(index)
		},
		loop(index) {
			if (index >= 5) index = 0
			this.selected = index
			this.setT = setTimeout(() => {
				this.loop(++index)
			}, 4000);
		}
	},
	mounted() {
		this.loop(this.selected)
	},
}
</script>

<style scoped lang="scss">
	section.comment {
		width: 100%;
		padding: 100px 0 150px;
	}
	.comment-cover {
		display: flex;
		width: 1130px;
		margin: 0 auto;
		justify-content: center;
	}
	.comment-item {
		width: 324px;
		height: 256px;
		background: #FFF;
		box-sizing: border-box;
		padding: 30px 24px 18px;
		box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.08);
		font-size: 16px;
		margin-left: 50px;
		h6 {
			font-size: 16px;
			line-height: 19px;
			margin-bottom: 10px;
		}
		p {
			font-size: 16px;
			line-height: 19px;
		}
		.comment-main {
			height: 174px;
		}
		.comment-footer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			p {
				display: flex;
				align-items: center;
			}
			.avatar {
				width: 36px;
				height: 36px;
				margin-right: 10px;
			}
			span {
				color: #000;
			}
			span.say {
				font-size: 66px;
				line-height: 36px;
				color: #D7DAE1;
				position: relative;
				top: 16px;
			}
		}
	}
	
	
</style>
