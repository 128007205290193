<template>
	<div :class="btnType === 1 ?  'button-list' : btnType === 2 ? 'button-list dark' : 'button-list dark near'">
		<button class="btn" @click="copyTxt()" @mouseenter="showAppleUrl = true" @mouseleave="showAppleUrl = false">
			<i class="apple-store"></i>
			App Store
			<img v-show="showAppleUrl" class="qrcode" :src="qrcodeImg" alt="">
		</button>
		<button class="btn" @click="copyTxt()" @mouseenter="showGoogleUrl = true;" @mouseleave="showGoogleUrl = false">
			<i class="google-play"></i>
			Google Play
				<img v-if="showGoogleUrl" class="qrcode" :src="qrcodeImg" alt="">
		</button>
		<button class="btn" @click="toApkDownload">
			<i class="apk-download"></i>
			APK Download
		</button>

	</div>
</template>

<script>
import qrcodeImg from '@images/qrcode.png'
export default {
	data() {
		return {
			qrcodeImg,
			copyTxt: window.copyTxt,
			showAppleUrl: false,
			showGoogleUrl: false,
		}
	},
	props: {
		btnType: {
			type: Number,
			default: 1
		} 
	},
	methods: {
		toApkDownload() {
			window.copyTxt && window.copyTxt()
      window.location.href = window.apkUrl
    },
	},
}
</script>

<style scoped lang="scss">
	button {
		cursor: pointer;
		border: none;
		::after {
			border: none;
			outline: none;
		}
	}
	.button-list {
			&.dark {
				button {
					background-color: #232325;
				}
				.qrcode {
					position: absolute;
					bottom: -180px;
					left: 0;
					z-index: 19;
				}
			}

			&.near {
				button {
					margin-right: 10px;
					width: 160px;
					height: 54px;
					line-height: 54px;
					font-size: 16px;
				}
				.apple-store {
					position: relative;
					width: 15px;
					height: 18.3px;
				}
				.google-play {
					width: 16px;
					height: 16px;
				}
				.apk-download {
					width: 16px;
					height: 18.6px;
				}
			}
			button {
				width: 186px;
				height: 64px;
				background: rgba(0, 0, 0, 0.2);
				border: 1px solid #FFFFFF;
				box-sizing: border-box;
				border-radius: 2px;
				font-size: 18px;
				line-height: 64px;
				color: #fff;
				margin-right: 24px;
				// margin-top: 35px;
				position: relative;
			}
			.qrcode {
				position: absolute;
				bottom: -210px;
				left: 0;
				z-index: 19;
			}
			i {
				display: inline-block;
				background-size: cover;
				position: relative;
				top: 4px;
			}
			.apple-store {
				width: 20px;
				height: 24px;
				background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAxCAMAAABwO3AyAAAAOVBMVEUAAAD///////////////////////////////////////////////////////////////////////8KOjVvAAAAEnRSTlMAIO/fQL9gMBCfUIB/cM+vkI8Zqqg+AAAA+UlEQVRIx62U264DIQhFFbzPnf//2DOp9NC0STcPXW/OrGw0IuFH1BI9WltJJDnEQXJTsbjKA7eYoRhlUqDJU2QopilShOZptQH5kZiCy6Tuup5URvv2ez1yvkxp6bo/HNv7NZVdJrNsS5lE4RKMmOWF/eokr/D/nivJd7hqIhAtlQWyzMMAyy4BRw5tbkfpSXecxlm8q9jwLq1rAdVtxp+bVr1Cc7OnDchBIagmNXd36CmQ1Q7vUxsJ5ojWTAgrj7AxBGDrekAJzlB2d0mx0QofiNIYvjnfZLpFY0MNaixoMHyqdJYY6+isy2fi27ilvLWgjL4LL8/lHz1MUretQJ36AAAAAElFTkSuQmCC");
			}
			.google-play {
				width: 22px;
				height: 22px;
				background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAMAAAApWqozAAAAz1BMVEUAAAAQz/8Q73gP8H76Pk36PUz5PkwN733/QFAO8X33PEz/yAj/ygr6Pk3/ygr/ygr3OFD/xwgP8H77UET/wA76PUwO8X7/yAnnSFoQzP//zxAfxfMQz/9C52ZG5G3/ygoP8H//ygn/yQn/ygsO8X3/yQr6ZDkQ5Kc16mr/ygsM73wQz/8Qz/8Qz//6Pk0P8H7/ygoQ1ueFhqYQ378P7oaidJAQ289omLw86GjwzBH7WEH/wQ/hzxj/uBI8tN1ZoceUfZst63DsR1jsYUv7T0XZmdnHAAAALXRSTlMAYCDf77+AYBCfQEDv39+fICDv7++Yf3BoUBDv39/Qz7+/r5CPgHBgYGBAMCCyvbV0AAABKklEQVQ4y43U11LDMBRFURFMje1AIKTQe3WhB0jo//9NxHcYtpFzJe/n9eAjeWSMOW23lxqmZlu3WZZt1OOXSVLo2YU6uJ+IrsdnEtHCL/wYnbFUxWi4jtEcjIrRLNWx9JDB3Rgt7TV8GC1LVYymo4aOpes7cXy6htHwMxs79McoPrewqoejPM/jpgOjh0+51FpVsWgsHGxrsRR3NSx6Wyy1wBW9E+ZWTRW/fr3Uxm+f6bOlD4yCH9NJ/3R4zMCKFb3+Zw8HBlyx0tqv3udSFCt6PKG7juu+SUt9j8OuITCWopOBsbBmg/lF++dXbQcKnm7nVjDgsoUue54CbNSzEBjLLjfGQlX8Xtrlw/fscmJsBFXqi5VdxttVYTkCT5uF7cguf70gkF3+fgAvzrKjdJEPKAAAAABJRU5ErkJggg==")
			}
			.apk-download {
				top: 5px;
				width: 22px;
				height: 26px;
				background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAA0CAMAAADCKvr4AAAAP1BMVEUAAABlpw1mqwpkpw1ipw1kpw5kpw5kpg5kowxgnxBjpg5lqA1ipQ1jpQtkqA5kpwxjpg1lqA5kqA5kpg5kpw4croasAAAAFHRSTlMAnx+/YN/vgEAQcK9gMI9AUM/Pz8gfHgoAAAEOSURBVEjH7ZXdboMwDEYdG7vND9Btfv9nnZdEIEaTqL3qBefGgA5fTIRiOLIu8I9lhQ70gAOJoAcyHm5/ELoE8veFWIRpeYCboA+y6IbwIPwmeuTWkT3piW9sN/IXF2SPDvaEfUP/MsMBBK1EgGSFGn1r0Z1WqL4aWq0YMepGXMoGPWsn6VNa8dRwG91rGznbfurg4W1SjLMV18HS7y6mbbsnAO2AkOoPhOVjRjrnaotoZqTrpV/6m/r8mu5FDTfSSQ0Gi2dVwpGOZslcRoyVgZ4tD5WxnvlYndo2wxl0RCTlGCGDi2pXroa3lnDluNqDL/1V3WXntk9C6uqrGoJgeC5zosvESjNkkFQCHPgF6DFf2kB3ag0AAAAASUVORK5CYII=");
			}
			
		}
</style>