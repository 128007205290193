var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        _vm.btnType === 1
          ? "button-list"
          : _vm.btnType === 2
          ? "button-list dark"
          : "button-list dark near"
    },
    [
      _c(
        "button",
        {
          staticClass: "btn",
          on: {
            click: function($event) {
              return _vm.copyTxt()
            },
            mouseenter: function($event) {
              _vm.showAppleUrl = true
            },
            mouseleave: function($event) {
              _vm.showAppleUrl = false
            }
          }
        },
        [
          _c("i", { staticClass: "apple-store" }),
          _vm._v("\n\t\tApp Store\n\t\t"),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showAppleUrl,
                expression: "showAppleUrl"
              }
            ],
            staticClass: "qrcode",
            attrs: { src: _vm.qrcodeImg, alt: "" }
          })
        ]
      ),
      _c(
        "button",
        {
          staticClass: "btn",
          on: {
            click: function($event) {
              return _vm.copyTxt()
            },
            mouseenter: function($event) {
              _vm.showGoogleUrl = true
            },
            mouseleave: function($event) {
              _vm.showGoogleUrl = false
            }
          }
        },
        [
          _c("i", { staticClass: "google-play" }),
          _vm._v("\n\t\tGoogle Play\n\t\t\t"),
          _vm.showGoogleUrl
            ? _c("img", {
                staticClass: "qrcode",
                attrs: { src: _vm.qrcodeImg, alt: "" }
              })
            : _vm._e()
        ]
      ),
      _c("button", { staticClass: "btn", on: { click: _vm.toApkDownload } }, [
        _c("i", { staticClass: "apk-download" }),
        _vm._v("\n\t\tAPK Download\n\t")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }