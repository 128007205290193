<template>
	<section class="try">
		<div class="try-cover">
			<get-message 
				:width="750"
				:msgType="2">
				<get-marki :btnType="2"></get-marki>
			</get-message>
			<img class="try-phone" :src="phone" alt="">
		</div>
	</section>
</template>

<script>
import getMarki from '@component/get-marki'
import getMessage from '@component/get-message'
import phone from '@images/phone.png'
export default {
	data() {
		return {
			phone,
		}
	},
	components: {
		getMarki,
		getMessage
	},
	methods: {
	},
	mounted() {
		
	},
}
</script>

<style scoped lang="scss">
	section.try {
		width: 100%;
		padding: 60px 0 60px;
		.try-cover {
			width: 1130px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
		}
		.try-phone {

		}
	}	
</style>
