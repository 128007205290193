var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "try" }, [
    _c(
      "div",
      { staticClass: "try-cover" },
      [
        _c(
          "get-message",
          { attrs: { width: 750, msgType: 2 } },
          [_c("get-marki", { attrs: { btnType: 2 } })],
          1
        ),
        _c("img", {
          staticClass: "try-phone",
          attrs: { src: _vm.phone, alt: "" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }