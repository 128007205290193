<template>
  <div class="partner" :class="classStyle">
    <img src="@images/p0.png" alt="">
    <div class="main">
      <img class="p1" src="@images/p1.png" alt="">
      <img class="p2" src="@images/p2.png" alt="">
      <img class="p2" src="@images/p3.png" alt="">
      <img class="p2" src="@images/p4.png" alt="">
      <p class="tel"><a href="tel:+6585392176">+6585392176</a> / <a href="tel:+65 85392176">+65 85392176</a></p>
    </div>
   
  </div>
</template>
<script>
export default {
  props: {
    classStyle: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
  .partner {
    text-align: center;
  }
  img {
    user-select: none;
  }
  .main {
    box-sizing: border-box;
    padding: 0 .2rem .75rem;
    position: relative;
    min-height: 1000px;
  }
  .p1 {
    margin-top: -1rem;
  }
  .p2 {
    margin-top: .5rem;
  }
  p.tel {
    font-weight: 500;
    font-size: .34rem;
    line-height: .4rem;
    color: #FF6835;
    position: absolute;
    bottom: 1.18rem;
    left: 27%;
    a {
      text-decoration: none;
      color: #FF6835;
    }
  }
  .pc {
  
    .main {
      box-sizing: border-box;
      padding: 0 16px 60px;
      position: relative;
      min-height: 1000px;
    }
    .p1 {
      margin-top: -80px;
    }
    .p2 {
      margin-top: 40px;
    }

    p.tel {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #FF6835;
      position: absolute;
      letter-spacing: 1px;
      bottom: 95px;
      left: 27%;

      a {
        color: #FF6835;
        text-decoration: none;
      }
    }
  }
  
</style>