<template>
  <div id="navTitle" class="nav-title">
    <nav class="nav-title-content">
      <span class="logo"></span>
      <ul>
        <li :class="item.key === selected ? 'active' : ''" v-for="item in liMap" :key="item.key">
          <router-link :to="item.path">{{item.key}}</router-link>
        </li>
        <li>
          <a href="/blog">Blog</a>
        </li>
      </ul>

      <span class="computer"></span>
      <span class="dashboard">
        <a target="_blank" href="https://manage.markicam.com/">Dashboard</a>
      </span>
    </nav> 
  </div>

</template>

<script>
export default {

  data () {
    return {
      title: 'Vue.js From LegoFlow',
      liMap: [{
        key: 'Product',
        path: '/'
      }, {
        key: 'Features',
        path: '/features'
      }, {
        key: 'Industries',
        path: '/industries/0'
      }, {
        key: 'Get Started',
        path: '/get-start'
      }, {
        key: 'About Us',
        path: '/about'
      }],
    }
  },
  props: {
    selected: {
      type: String,
      default: 'Product'
    }
  },
  mounted() {
    const self = this
		const nav = document.getElementById('navTitle')
    this.onScroll = (e) => {
			var osTop = document.documentElement.scrollTop || document.body.srcollTop;
			if (osTop > 68) {
				nav.className = 'nav-title active';
			} else {
				nav.className = 'nav-title';
			}
		}
    document.addEventListener('scroll', this.onScroll)
    this.onScroll()
	},
	beforeDestroy() {
		document.removeEventListener('scroll', this.onScroll)
	},
}
</script>

<style scoped lang="scss">
  .nav-title {
    width: 100%;
    height: 68px;
    line-height: 68px;
    font-size: 14px;
    background-color: #fff;
    &.active {
      position: fixed;
      top: 0;
      z-index: 99;
    }
    .nav-title-content {
      width: 1130px;
      margin: 0 auto;
      color: #ADB0B7;
      display: flex;
      align-items: center;
      ul {
        flex-grow: 1;
      }
      li a {
        color: #ADB0B7;
        font-weight: bold;
      }
      li {
        display: inline-block;
        margin-right: 40px;
      }
      li.active a {
        color: #232323;
      }
    }
    span.logo {
      display: inline-block;
      width: 86px;
      height: 28px;
      background: url(../images/logo.png) no-repeat center;
      background-size: 100%;
      margin-right: 74px;
    }
    .dashboard {
      // flex-direction: flex-end;
      color: #232323;
      cursor: pointer;
    }
    .computer {
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-right: 11px;
      display: inline-block;
      background: url(../images/computer.png) no-repeat center;
      background-size: 100%;
    }
  }
</style>
