var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "partner", class: _vm.classStyle }, [
    _c("img", { attrs: { src: require("@images/p0.png"), alt: "" } }),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main" }, [
      _c("img", {
        staticClass: "p1",
        attrs: { src: require("@images/p1.png"), alt: "" }
      }),
      _c("img", {
        staticClass: "p2",
        attrs: { src: require("@images/p2.png"), alt: "" }
      }),
      _c("img", {
        staticClass: "p2",
        attrs: { src: require("@images/p3.png"), alt: "" }
      }),
      _c("img", {
        staticClass: "p2",
        attrs: { src: require("@images/p4.png"), alt: "" }
      }),
      _c("p", { staticClass: "tel" }, [
        _c("a", { attrs: { href: "tel:+6585392176" } }, [
          _vm._v("+6585392176")
        ]),
        _vm._v(" / "),
        _c("a", { attrs: { href: "tel:+65 85392176" } }, [
          _vm._v("+65 85392176")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }