<template>
  <section class="case">
    <sction-title title="Who uses Marki for employee management?" />
    <div class="swiper-cover">
      <a
        style="cursor: pointer"
        @click="scrollToTop(contentList[selected].link)"
      >
        <div
          :style="`background-image: url(${contentList[selected].bg})`"
          class="swiper-img"
        >
        </div>
      </a>
      <div class="case-list">
        <h4>Case</h4>
        <ul>
          <li
            @click="setSelected(index)"
            :key="index"
            :class="selected === index ? 'active' : ''"
            v-for="(item, index) in contentList"
          >
            <i :class="`iconfont ${item.icon}`"></i>
            {{ item.case }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import sctionTitle from '@component/section-title'
export default {
  data () {
    return {
      selected: 0,
      setT: null,
      contentList: [
        {
          bg: 'https://cdn.markicam.com/mkc/20240520/Construction.jpg',
          case: 'Construction',
          title: 'Keep your crew and project progress on time',
          link: '/industries/0',
          icon: 'iconchengshi',
          desc:
            'Monitor performance of workers with anti-tamper time and GPS location to keep construction sites safe and productive'
        },
        {
          bg: 'https://cdn.markicam.com/mkc/20240520/Construction1.jpg',
          case: 'Cleaning',
          title: 'Keep janitorial time and work results on time',
          link: '/industries/1',
          icon: 'iconhuishou',
          desc:
            'Track attendance and janitorial results of your crew in fixed or flexible workplace anytime, anywhere'
        },
        {
          bg: 'https://cdn.markicam.com/mkc/20240520/Construction2.jpg',
          case: 'Security',
          title: 'Visualize security tasks and sync to your team',
          link: '/industries/2',
          icon: 'iconren',
          desc:
            'ntergrate and optimize security process based on patrol routes with our advanced technology'
        },
        {
          bg: 'https://cdn.markicam.com/mkc/20240520/Construction3.jpg',
          case: 'Maintenance',
          title: 'Reliable and accurate  proofs for maintenance work results',
          link: '/industries/3',
          icon: 'iconshezhi',
          desc:
            'View in real-time to see maintenance task completion and team performance at each location'
        },
        {
          bg: 'https://cdn.markicam.com/mkc/20240520/Construction4.jpg',
          case: 'Health & Safety',
          title: 'Keep COVID-19 Prevention and Control measures on track',
          link: '/industries/4',
          icon: 'iconyiyao',
          desc:
            'Ensure all the prevention and control measures are implemented in the workplace'
        }
      ]
    }
  },
  components: {
    sctionTitle
  },
  methods: {
    scrollToTop (link) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.$router.push({ path: link })
    },
    setSelected (index) {
      clearTimeout(this.setT)
      this.loop(index)
    },
    loop (index) {
      if (index >= 5) index = 0
      this.selected = index
      this.setT = setTimeout(() => {
        this.loop(++index)
      }, 4000)
    }
  },
  mounted () {
    this.loop(this.selected)
  }
}
</script>

<style scoped lang="scss">
section.case {
  width: 100%;
  background-color: #f6f7fb;
  padding: 100px 0 150px;
}
.swiper-cover {
  width: 1180px;
  height: 500px;
  margin: 0 auto;
  display: flex;
}
.swiper-img {
  width: 933px;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  transition: background-image 0.75s linear;
  color: #fff;
  display: flex;
  padding-left: 40px;
  flex-direction: column-reverse;
  h4 {
    font-size: 40px;
    line-height: 46px;
    width: 500px;
    margin-bottom: 15px;
    text-align: left;
    width: 700px;
  }
  p {
    width: 700px;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 40px;
  }
}
.case-list {
  width: 247px;
  height: 500px;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  padding-top: 48px;
  .iconfont {
    font-size: 24px;
  }
  h4 {
    color: #232325;
    font-size: 24px;
  }
  li {
    margin-top: 35px;
    font-size: 20px;
    color: #adb0b7;
    padding-left: 40px;
    text-align: left;
    cursor: pointer;
    &.active {
      color: #2e5bff;
    }
    .iconfont {
      margin-right: 10px;
    }
  }
}
</style>
