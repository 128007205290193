var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav-title"),
      _c("nav-header"),
      _c("index-device"),
      _c("index-swiper"),
      _c("index-customer"),
      _c("section", {
        staticClass: "section6",
        style: "background-image: url(" + _vm.section6bg + ")"
      }),
      _c("index-try"),
      _c("section", {
        staticClass: "section7",
        style: "background-image: url(" + _vm.section7bg + ")"
      }),
      _c("partner-banner"),
      _c("nav-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }