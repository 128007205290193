<template>
		<div class="content-title">
			<h2 :class="category === 1 ? '' : 'min'">{{title}}</h2>
			<span :class="category === 1 ? 'bar' : 'bar min'"></span>
		</div>
    
</template>

<script>
export default {
    props: {
      title: {
        type: String,
        default: ''
			},
			category: {
				type: Number,
				default: 1
			}
  	}
}
</script>

<style scoped lang="scss">
.content-title {
	color: #232325;
	text-align: center;
	h2 {
		font-size: 40px;
		line-height: 46px;
	}
	h2.min {
		font-size: 36px;
		line-height: 150%;
	}
	.bar {
		display: inline-block;
		background-color: #2E5BFF;
		width: 46px;
		height: 6px;
		margin-top: 30px;
		margin-bottom: 70px;
	}
}
</style>
